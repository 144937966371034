import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import ProjectBlock from "components/ProjectBlock"
import ProjectBlock2 from "components/ProjectBlock2"
import ProjectBlock3 from "components/ProjectBlock3"
import ProjectBlock4 from "components/ProjectBlock4"

// track the current slogan number
let currentSlogan = 0

const RenderBody = ({ home, projects, meta }) => (
  <>
    <Helmet
      title={meta.title}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `viewport`,
          content:
            "width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0",
        },
        {
          property: `og:title`,
          content: meta.title,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />

    {projects.map((project, i) => {
      // set the current slogan text depending currentSlogan if no one is available return empty array
      let sloganText =
        currentSlogan < home.slogans.length
          ? home.slogans[currentSlogan].slogan
          : []

      // increase the number of currentSlogan when the ProjectBlock contains a slogan
      if (
        project.node.section_type === "Style A" ||
        project.node.section_type === "Style C" ||
        project.node.section_type === "Style D"
      ) {
        currentSlogan++
      }

      switch (project.node.section_type) {
        case "Style A":
          return (
            <ProjectBlock
              key={i}
              category={project.node.project_category}
              title={project.node.project_title}
              description={project.node.project_preview_description}
              thumbnail={project.node.project_preview_thumbnail}
              uid={project.node._meta.uid}
              images={project.node.image_block_top}
              homeSlogan={sloganText}
            />
          )

        case "Style B":
          return (
            <ProjectBlock2
              key={i}
              category={project.node.project_category}
              title={project.node.project_title}
              description={project.node.project_preview_description}
              thumbnail={project.node.project_preview_thumbnail}
              uid={project.node._meta.uid}
              images={project.node.image_block_top}
            />
          )

        case "Style C":
          return (
            <ProjectBlock3
              key={i}
              category={project.node.project_category}
              title={project.node.project_title}
              description={project.node.project_preview_description}
              thumbnail={project.node.project_preview_thumbnail}
              uid={project.node._meta.uid}
              images={project.node.image_block_top}
              homeSlogan={sloganText}
            />
          )

        case "Style D":
          return (
            <ProjectBlock4
              key={i}
              category={project.node.project_category}
              title={project.node.project_title}
              description={project.node.project_preview_description}
              thumbnail={project.node.project_preview_thumbnail}
              uid={project.node._meta.uid}
              images={project.node.image_block_top}
              homeSlogan={sloganText}
            />
          )

        default:
          return ""
      }
    })}
  </>
)

export default ({ data }) => {
  //Required check for no data being returned
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  const projects = data.prismic.allProjects.edges
  const meta = data.site.siteMetadata
  if (!doc || !projects) return null

  return (
    <Layout>
      <RenderBody home={doc.node} projects={projects} meta={meta} />
    </Layout>
  )
}

RenderBody.propTypes = {
  home: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    prismic {
      allHomepages {
        edges {
          node {
            slogans {
              slogan
            }
          }
        }
      }
      allProjects(tags: "Homepage", sortBy: project_post_date_DESC) {
        edges {
          node {
            project_title
            project_preview_description
            project_preview_thumbnail
            project_category
            project_post_date
            _meta {
              uid
              tags
            }
            image_block_top {
              gallery_image
            }
            section_type
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
