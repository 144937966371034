import React from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const ProjectBlockContainer = styled("section")`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: calc(100vw / 50);
  grid-row-gap: calc(100vw / 50);

  text-decoration: none;
  color: currentColor;
  margin-bottom: calc(100vw / 40);

  @media (max-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
  }
`

const ProjectTitleContainer = styled("div")`
  grid-column-start: 7;
  grid-column-end: 13;
  grid-row-start: 1;
  white-space: nowrap;
  z-index: -1;
  line-height: normal;

  top: 200px;
  position: relative;

  @media (max-width: 768px) {
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 1;

    top: 80px;
  }
`

const ProjectBlockImageContainer = styled(Link)`
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;

  & img {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row-start: 2;
  }
`

const ProjectBlockImageContainer2 = styled(Link)`
  grid-column-start: 7;
  grid-column-end: 12;
  grid-row-start: 3;

  & img {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 4;

    top: -20px;
    position: relative;
  }
`
const ProjectSloganContainer = styled("div")`
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 2;
  z-index: 1;

  @media (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;

    top: -20px;
    position: relative;
  }
`

class ProjectBlock4 extends React.Component {
  render() {
    return (
      <ProjectBlockContainer className="ProjectBlockContainer">
        {this.props.images[0] && (
          <ProjectBlockImageContainer
            to={`/work/${this.props.uid}`}
            className="imageWrapper"
          >
            <img
              src={this.props.images[0].gallery_image.url}
              alt={this.props.title[0].text}
            />
          </ProjectBlockImageContainer>
        )}
        <ProjectTitleContainer
          to={`/work/${this.props.uid}`}
          data-parallax="0.2"
        >
          <span className="decoXXL">{this.props.title[0].text}</span>
        </ProjectTitleContainer>

        {this.props.images[1] && (
          <ProjectBlockImageContainer2
            to={`/work/${this.props.uid}`}
            className="imageWrapper"
            data-parallax="-0.1"
          >
            <img
              src={this.props.images[1].gallery_image.url}
              alt={this.props.title[0].text}
            />
          </ProjectBlockImageContainer2>
        )}

        <ProjectSloganContainer className="quoteL" data-parallax="-0.2">
          {RichText.render(this.props.homeSlogan)}
        </ProjectSloganContainer>
      </ProjectBlockContainer>
    )
  }
}

export default ProjectBlock4

ProjectBlock4.propTypes = {
  category: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  thumbnail: PropTypes.object.isRequired,
  title: PropTypes.array.isRequired,
  description: PropTypes.array.isRequired,
  uid: PropTypes.string.isRequired,
}
