import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import dimensions from "styles/dimensions"

const ProjectBlockContainer = styled("section")`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: calc(100vw / 50);
  grid-row-gap: calc(100vw / 50);

  text-decoration: none;
  color: currentColor;
  margin-bottom: calc(100vw / 40);

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-template-columns: repeat(6, 1fr);
  }
`

const ProjectTitleContainer = styled("div")`
  grid-column-start: 7;
  grid-column-end: 13;
  grid-row-start: 1;
  white-space: nowrap;
  z-index: -1;
  line-height: normal;

  align-self: start;
  top: -160px;
  position: relative;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 1;

    top: -30px;
  }
`

const ProjectBlockImageContainer = styled(Link)`
  grid-column-start: 8;
  grid-column-end: 10;
  grid-row-start: 1;

  align-self: center;
  top: 64px;
  position: relative;

  & img {
    max-width: 100%;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 1;
    top: 80px;
  }
`

const ProjectBlockImageContainer2 = styled(Link)`
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;

  top: -150px;
  position: relative;

  & img {
    max-width: 100%;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;

    top: 30px;
  }
`

const ProjectBlockImageContainer3 = styled(Link)`
  grid-column-start: 8;
  grid-column-end: 13;
  grid-row-start: 2;

  top: 150px;
  position: relative;

  & img {
    max-width: 100%;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;

    top: 60px;
  }
`

const ProjectBlockImageContainer4 = styled(Link)`
  grid-column-start: 5;
  grid-column-end: 8;
  grid-row-start: 3;

  & img {
    max-width: 100%;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 4;

    position: relative;
  }
`

class ProjectBlock2 extends React.Component {
  render() {
    return (
      <ProjectBlockContainer className="ProjectBlockContainer">
        {this.props.images[0] && (
          <ProjectBlockImageContainer
            to={`/work/${this.props.uid}`}
            className="imageWrapper"
          >
            <img
              src={this.props.images[0].gallery_image.url}
              alt={this.props.title[0].text}
            />
          </ProjectBlockImageContainer>
        )}

        <ProjectTitleContainer
          data-parallax="-0.1"
          to={`/work/${this.props.uid}`}
        >
          <span className="decoXXL">{this.props.title[0].text}</span>
        </ProjectTitleContainer>

        {this.props.images[1] && (
          <ProjectBlockImageContainer2
            to={`/work/${this.props.uid}`}
            className="imageWrapper"
          >
            <img
              src={this.props.images[1].gallery_image.url}
              alt={this.props.title[0].text}
            />
          </ProjectBlockImageContainer2>
        )}

        {this.props.images[2] && (
          <ProjectBlockImageContainer3
            to={`/work/${this.props.uid}`}
            className="imageWrapper"
            data-parallax="0.1"
          >
            <img
              src={this.props.images[2].gallery_image.url}
              alt={this.props.title[0].text}
            />
          </ProjectBlockImageContainer3>
        )}

        {this.props.images[3] && (
          <ProjectBlockImageContainer4
            to={`/work/${this.props.uid}`}
            className="imageWrapper"
            data-parallax="-0.1"
          >
            <img
              src={this.props.images[3].gallery_image.url}
              alt={this.props.title[0].text}
            />
          </ProjectBlockImageContainer4>
        )}
      </ProjectBlockContainer>
    )
  }
}

export default ProjectBlock2

ProjectBlock2.propTypes = {
  category: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  thumbnail: PropTypes.object.isRequired,
  title: PropTypes.array.isRequired,
  description: PropTypes.array.isRequired,
  uid: PropTypes.string.isRequired,
}
